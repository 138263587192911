.receipt {
  position: relative;
  padding: 20px;
  border: 1px solid #ddd;
  margin-top: 20px;
  width: 400px;
  background-color: #fff;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .receipt-header {
    background-color: #0079B0;
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 10px 10px 0 0;

    .logo {
      margin-bottom: 10px;
      img {
        max-width: 100px;
      }
    }

    h1 {
      margin: 0;
      font-size: 24px;
    }
  }

  .receipt-body {
    padding: 20px;

    h2 {
      text-align: center;
      margin: 10px 0;
      font-size: 22px;
      color: #0079B0;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;

      th, td {
        border: 1px solid #0079B0;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: #f9f9f9;
        color: #333;
      }
    }

    .total {
      margin-top: 10px;
      text-align: right;
      font-size: 18px;
      font-weight: 600;
    }

    .signature {
      margin-top: 20px;
      text-align: left;
      font-size: 16px;
      color: #555;
    }
  }

  .receipt-footer {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 0 0 10px 10px;
    text-align: center;

    p {
      margin: 5px 0;
      color: #333;
      font-size: 14px;
    }

    p:first-of-type {
      font-weight: bold;
    }
  }
}

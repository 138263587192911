.featured{
    flex: 2;
    .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: grey;
    }
    .bottom{
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        .featuredChart{
            width: 100px;
            height: 100px;
        }
        .title{
            font-weight: 500;
            color: grey;
        }
        .amount{
            font-size: 30px;
        }
        .desc{
            font-weight: 300;
            font-size: 12px;
            color: grey;
            text-align: center;
        }
        .summary{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item{
                text-align: center;
                .itemTitle{
                    font-size: 14px;
                    color: grey;
                }
                .itemResult{
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    font-size: 14px;
                }
                .positive{
                    color: green;
                }
                .negative{
                    color: red;
                }
            }
        }
    }
}
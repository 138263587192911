.salesPage {
  display: flex;
  //   padding: 20px;
  //   width: 100%;
  .top {
    flex: 6;
    display: block;
    .salesAction {
      //   width: 100%;
      padding: 20px;
      //   border: 1px solid red;
      h1 {
        color: grey;
        text-align: center;
        margin-bottom: 20px;
      }
      .itemSelection {
        text-align: center;
        margin-bottom: 20px;
      }
      .search {
        select,
        input {
          width: 30%;
          padding: 20px;
          font-size: 16px;
        }
      }
      .selectedItems {
        margin-bottom: 20px;
        h2 {
          margin-bottom: 10px;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          th,
          td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f4f4f4;
          }
        }
      }
      .summary {
        text-align: center;
        h2 {
          margin-bottom: 10px;
        }
        p {
          font-size: 18px;
          margin-bottom: 20px;
        }
        button {
          padding: 10px 20px;
          font-size: 16px;
          background-color: #7451f8;
          color: white;
          border: none;
          cursor: pointer;
          &:hover {
            background-color: #45a049;
          }
        }
      }
    }
  }
}

.home{
    display: flex;
    .homeContainer{
        flex: 6;
        .widgets, .charts{
            display: flex;
            padding: 20px;
            gap: 20px;
        }
        .charts{
            padding: 5px 20px;
        }
        .listContainer{
            border: .5px solid grey;
            padding: 20px;
            margin: 20px;
            .listTitle{
                font-weight: 500;
                color: gray;
                margin-bottom: 15px;
            }
        }
    }
}
.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .auth-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .auth-form h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
  }
  
  .auth-form label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .auth-form input {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .auth-form button {
    width: 100%;
    display: flex;
    padding: 10px;
    background-color: #7451f8;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .auth-form button:hover {
    background-color: #673efa;
  }
  
  .error {
    color: red;
    margin-bottom: 10px;
  }
  
  .message {
    color: green;
    margin-bottom: 10px;
  }
  
button{
  margin-top: 10px;
}
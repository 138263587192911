.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    outline: none;
    h2{
      color: #7451f8;
    }
    
  }
  
  form {
    display: flex;
    flex-direction: column;
  
    label {
      margin: 10px 0;
    }
    // this tyling applies to the edit profile modal too
    .btns{
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      button {
        margin: 10px 0;
        padding: 10px;
        &:nth-child(1){
          // background-color: green;
          border: 1px solid #7451f8;
          outline: none;
          color: #7451f8;
          // border: none;
          border-radius: 5px;
        }
        &:nth-child(2){
          border: 1px solid #7451f8;
          outline: none;
          color: orangered;
          border-radius: 5px;

        }
      }
    }
  }
  
/* Table.scss */

.table {
    width: 100%;
    margin-top: 20px;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  thead {
    background-color: #f2f2f2;
    border-bottom: 2px solid #ddd;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
  }
  
  th {
    font-weight: bold;
    color: #333;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  /* Additional styles for specific elements */
  
  .itemTitle {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .detailItem {
    margin-bottom: 8px;
  }
  
  .itemKey {
    font-weight: bold;
    color: #555;
  }
  
  .itemValue {
    color: #333;
  }
  
.navbar{
    height: 50px;
    border-bottom: 0.5px solid rgb(231, 228, 228);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;

    .wrapper{
        display: flex;
        padding: 20px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .search{
             display: flex;
             align-items: center;
             border: 0.5px solid lightgrey;
             padding: 3px;
             input{
                outline: none;
                border: none;
                background-color: transparent;
                &::placeholder{
                  font-size: 12px;  
                }
             }
        }
        .items{
            display: flex;
            align-items: center;

            .item{
                display: flex;
                align-items: center;
                margin-right: 20px;
                position: relative;
                .icon{
                    font-size: 20px;
                     
                }
                .avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                }
                .counter{
                    width: 15px;
                    height: 15px;
                    background-color: red;
                    border-radius: 50%;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    font-weight: bold;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                }
                .name,.role{
                    // border: 1px solid tomato;
                    font-weight: 800;
                    // display: block;
                    text-align: center;
                }
                
                
            }
            .item:last-child{
                display: block;
            }
        }
    }
}
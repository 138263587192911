.app.dark {
  background-color: rgb(30, 30, 30);
  color: rgb(156, 156, 156);
  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }
  .table {
    background-color: #111;
    .tableCell {
      color: gray;
    }
  }
  .navBar{
    color: #111;
    border-color: #333;
    .search{
        border-color: gray;
    }
  }
  .sidebar{
    color: #111;
    background-color: rgb(30, 30, 30);
    .top{
        .logo{
            color: #999;
        }
    }
    hr{
        border-color: #333;
    }
    ul{
        li{
            &:hover{
                background-color: #333;
            }
            .icon{
                color: #999;
            }
        }
    }
  }
  .datatable{
    .dataGrid{
      color: grey;
      border: none;
      .deleteBtn,.viewBtn{
        color: gray;
        border: none;
      }
    }
  }
  input{
    background-color: transparent;
  }
}

.profile {
  display: flex;
  width: 100%;
  .profileContainer {
    flex: 6;
    padding: 20px;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  border: 1px dotted grey;
}

.left {
  flex: 2;
  .title{
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    padding: 20px;
    color: #7451f8;
  }
  
  .item {
    display: flex;
    justify-content: space-around;
    .itemImg{
      border: 1px dotted grey;
      border-radius: 50%;
      align-self: stretch;
    }
    .itemTitle{
      color: #7451f8;
    }
    .details{
      font-weight: 600;
      color: rgb(51, 51, 51);
    }
  }
}
.right {
  flex: 1;
  border-left: 1px dotted grey;
  padding: 10px;
 
  font-weight: 600;
  .editBtn,
  .passwordChangeBtn {
    cursor: pointer;
    color: #7451f8;
    margin: 10px;
    border: 1px solid #7451f8;
    text-align: center;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: 800;

  }
  .editBtn:hover,
  .passwordChangeBtn:hover{
    background-color: rgb(221, 218, 218);
    transition: all .5s;
    // color: white;
  }
}

.itemImg {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.details {
  margin-left: 20px;
}

.detailItem {
  margin: 10px 0;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  form{
    input{
      border: none;
      outline: none;
      border-radius: 0;
      border-bottom: .5px solid #7451f8;;
    }
  }
}
